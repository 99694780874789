.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 150px 0px;
}

/* .btn-submit-login {
    margin: 10px 0px !important;
} */

.txt-username-login,
.txt-password-password {
  margin: 0px 0px 10px 0px !important;
}
