.complete {
  background-color: #616161;
  color: white;
}

.booking-item-list-icon-complete {
  color: white;
}

/* .item-list-icon-active {
    color: black;
  } */