.Graphite {
  background-color: #616161 !important;
  color: white !important;
}

.Tomato {
  background-color: #d30915 !important;
  color: white !important;
}

.Basil {
  background-color: #167f46 !important;
  color: white !important;
}

.Peacock {
  background-color: #1b9de2 !important;
  color: white !important;
}

.Tangerine {
  background-color: #f2522c !important;
  color: white !important;
}

.Banana {
  background-color: #f5be3b !important;
  color: white !important;
}

.Grape {
  background-color: #8d2ca8 !important;
  color: white !important;
}
