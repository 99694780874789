.dashboard-container {
  border: 1px dashed red;
  padding: 10px;
}

.btn-item-dashboard {
  margin: 10px 0px !important;
  padding: 20px 0px !important;
}

.btn-item-dashboard span {
    font-weight: 600;
}

.dashboard-user-info {
  padding-bottom: 10px;
}