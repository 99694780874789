.Graphite {
  background-color: #616161;
  color: white;
}

.Tomato {
  background-color: #d30915;
  color: white;
}

.Basil {
  background-color: #167f46;
  color: white;
}

.Peacock {
  background-color: #1b9de2;
  color: white;
}

.Tangerine {
  background-color: #f2522c;
  color: white;
}

.Banana {
  background-color: #f5be3b;
  color: white;
}

.Grape {
  background-color: #8d2ca8;
  color: white;
}

.working-item-list-icon-active {
  color: inherit;
}

.working-item-list-icon-complete {
  color: white;
}

.link-to-calendar {
  background-color: white;
  color: #1d1d1d;
}
