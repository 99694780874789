.line {
  /* border: 1px dashed gray; */
}

.invoice {
  border: 1px dashed gray;
  width: 70% !important;
  margin-left: 15% !important;
  box-shadow: 5px 10px #888888;
  padding: 20px 10px;
}

.booking-summary-header {
  text-align: center;
  width: 100%;
  padding: 30px 0px;
}

.content {
  padding: 0px 0px 10px 0px;
}