/* .App {
    font-family: sans-serif;
    text-align: center;
  }
  */
/* body {
    width: 100vh;
  } */

.overflowimgcontainer {
  height: 400px;
  width: 500px;
  overflow: scroll;
}
.overflowimgcontainer::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.grabbercontainer {
  cursor: grab;
  width: 100%;
  height: 100%;
}

img {
  object-fit: cover;
  position: relative;
}
