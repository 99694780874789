.section-name {
  text-align: center;
  /* display: inline-block;
  width: 100%; */
}

.section-header {
  /* display: inline-block; */
  /* /* width: 100%; */
  margin: 10px 0px 10px 0px !important; 
}
