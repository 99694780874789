.addon-session-quantity {
  font-weight: 600;
}

.addon-session-list {
  border: 1px dashed gray;
  width: 95% !important;
  margin-left: 1% !important;
  box-shadow: 5px 10px #888888;
  padding: 20px 6px;
  margin-top: 20px;
}

.content {
  height: calc(100vh - 100px);
}

.bottom {
  /* position: absolute;
  bottom: 0px; */
}

.addon-total {
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: white;
  /* width:100%; */
  border-radius: 8px;
  border: 1px solid black;
  padding: 5px;
  color: #dc2127;
}

.addon-list-wrapper:last-child {
  padding-bottom: 50px;
}
