.card_one {
  border-left: 5px solid red;
}

.card_two {
  border-left: 5px solid green;
}

.card_three {
  border-left: 5px solid grey;
}

.title {
  font-size: 18;
  margin-bottom: 5px;
}

.value {
  font-size: 18 !important;
  font-weight: 700 !important;
}
