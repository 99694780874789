.app-name {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 10px 0px 30px 0px !important;
}

.btn-submit {
  margin: 0px 0px 0px 0px !important;
}

.item-list {
  border: 1px solid gray;
  padding: 10px 0px;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 100% !important;
}

.list-item {
  height: calc(100% - 100px);
  overflow-y: auto;
}

.list-item-header {
  background-color: cornflowerblue;
  color: white;
}

.wrapper {
  padding: 0px 10px;
  height: 100vh;
}

.no-display {
  display: none;
}

.center-text {
  text-align: center;
}
