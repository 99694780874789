.allowance-session-quantity {
  font-weight: 600;
}

.allowance-session-list {
  border: 1px dashed gray;
  width: 95% !important;
  margin-left: 1% !important;
  box-shadow: 5px 10px #888888;
  padding: 20px 6px;
  margin-top: 20px;
}

.content {
  height: calc(100vh - 100px);
}

.bottom {
  /* position: absolute;
  bottom: 0px; */
}
